import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Seo from '../../components/layout/SEO';
import FaClock from '../../assets/faClock.svg';
import Layout from '../../components/layout/LayoutInner';
import InnerHead from '../../components/layout/InnerHead';
import MainQtrLeft from '../../components/layout/MainQtrLeft';
import WidgetPractices from '../../components/innerPage/WidgetPractices';

export default function index({ data }) {
  return (
    <Layout>
      <Seo
        title='Recent News'
        canonical='news/'
      />
      <InnerHead title="Recent News" caption="Dreyer Law&mdash;When Results Matter" />
      <main className="blog-page">
        <MainQtrLeft>
          {/* left */}
          <WidgetPractices />
          {/* right */}
          <div className="blog-page--index">
            <div className="blog-page--index__wrapper">
              <div className="blog-page--index__list">
                {data.allMdx.edges.map(({ node }) => {
                  const heroImage = getImage(node.frontmatter.hero_image);

                  return (
                    <div className="blog-page--item">
                      <div className="blog-page--item__thumb">
                        <Link to={`/news/${node.slug}`}>
                          <GatsbyImage image={heroImage} alt={node.frontmatter.title} />
                        </Link>
                      </div>
                      <div className="blog-page--item__header">
                        <h3 className="blog-page--item__title">
                          <Link to={`/news/${node.slug}`}>
                            {node.frontmatter.title}
                          </Link>
                        </h3>
                      </div>
                      <div className="blog-page--item__content">
                        {node.excerpt}
                      </div>
                      <div className="blog-page--item__meta">
                        <span>
                          <FaClock />
                          {node.frontmatter.date}
                        </span>
                        &nbsp;&bull;&nbsp;
                        <span className="blog-page--item__category">
                          {node.frontmatter.category}
                        </span>
                      </div>
                      <Link className="blog-page--item__button" to={`/news/${node.slug}`}>Read More</Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </MainQtrLeft>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query {
    allMdx(
      filter: {frontmatter: {category: {ne: "drunk driving"}, datum: {eq: "blog"}}}
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          frontmatter {
            category
            date(formatString: "MMMM Do, YYYY")
            hero_image {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 2.203
                  placeholder: BLURRED
                )
              }
            }
            title
          }
          id
          slug
          excerpt
        }
      }
    }
  }
`
