import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import FaPhoneAlt from '../../assets/faPhoneAlt.svg';
import FaClock from '../../assets/faClock.svg';
import FaEnvelopeOpen from '../../assets/faEnvelopeOpen.svg';

export default function WidgetPractices() {
  const practiceAreas = useStaticQuery(graphql`
    {
      allMdx(
        filter: {fileAbsolutePath: {glob: "/home/dan/dreyer/src/data/practice-areas/*"}}
        sort: {fields: frontmatter___order, order: ASC}
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            slug
          }
        }
      }
      site {
        siteMetadata {
          email
          phone
        }
      }
      file(relativePath: {eq: "patrick-fore-379185-unsplash.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const pitchPracticePageSidebar = getImage(practiceAreas.file);
  const stackedPracticePageSidebar = [`linear-gradient(rgba(47,47,47,.85),rgba(47,47,47,.85))`, pitchPracticePageSidebar];

  return (
    <div className="practice-page--sidebar">
      <h3>Practice Areas</h3>
      <ul>
        {practiceAreas.allMdx.edges.map(({ node }) => {
          return (
            <Fragment>
              <li>
                <Link to={`/practice-areas/${node.slug}`}>
                  {node.frontmatter.title}
                </Link>
              </li>
            </Fragment>
          )
        })}
      </ul>
      <BgImage image={stackedPracticePageSidebar} className="practice-page--sidebar__pitch">
        <h3>Aggressive Representation You Can Count On</h3>
        <p>Let our experienced personal injury attorneys get to work for you</p>
        <div>
          <FaPhoneAlt />
          {practiceAreas.site.siteMetadata.phone}
        </div>
        <div>
          <FaEnvelopeOpen />
          {practiceAreas.site.siteMetadata.email}
        </div>
        <div>
          <FaClock />
          Available 24/7
        </div>
      </BgImage>
    </div>
  )
}
